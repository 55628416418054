import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { Stack } from "@mui/material";

const MaskedTemplateField = ({
  name,
  value,
  label,
  onChange,
  mask,
  error,
  helperText,
  ...rest
}) => {
  const onHandleChange = (event) => {
    onChange(name, event.target.value.toUpperCase());
  };
  return (
    <Stack>
      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          onChange={onHandleChange}
          maskChar="_"
        >
          {(inputProps) => (
            <TextField {...inputProps} name={name} label={label} />
          )}
        </InputMask>
      ) : (
        <TextField
          type="text"
          name={name}
          label={label}
          value={value}
          onChange={(event) => onChange(name, event.target.value)}
          error={error[name]}
          helperText={helperText}
          {...rest}
        />
      )}
    </Stack>
  );
};

export default MaskedTemplateField;
