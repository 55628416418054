import React from "react";
import NumberTextFieldComponent from "./NumberTextFieldComponent";
import DatePickerComponent from "./DatePickerComponent";
import TextFieldComponent from "./TextFieldComponent";
import DropDownComponent from "./DropDownComponent";
import AutoCompleteComponent from "./AutoCompleteComponent";

function Input({ formDataItem, display, mask, error, helperText, ...rest }) {
  if (formDataItem.type == "number") {
    return (
      <NumberTextFieldComponent
        symbolAign={formDataItem.symbolAign}
        symbol={formDataItem.symbol}
        maxLength={formDataItem.maxLength}
        {...rest}
      />
    );
  }

  if (formDataItem.type == "date") {
    return (
      <DatePickerComponent
        format={formDataItem.format}
        views={formDataItem.views}
        {...rest}
      />
    );
  }

  if (formDataItem.type == "boolean") {
    return (
      <DropDownComponent
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        getOptionLabel={(data) => data.label}
        {...rest}
      />
    );
  }

  if (formDataItem.type == "dropDown") {
    return (
      <DropDownComponent api={formDataItem.api} display={display} {...rest} />
    );
  }

  if (formDataItem.type == "pincode") {
    return <AutoCompleteComponent display={display} {...rest} />;
  }

  // if (formDataItem.type == "objectAccountType") {
  //   return (
  //     <DropDownComponent
  //       options={[{ label: "applicant", value: "applicant" }]}
  //       getOptionLabel={(data) => data.label}
  //        {...rest}
  //     />
  //   );
  // }

  // if (formDataItem.type == "roleOnApplication") {
  //   return (
  //     <DropDownComponent
  //       options={[
  //         { label: "Applicant", value: "Applicant" },
  //         { label: "Co-Applicant", value: "Co-Applicant" },
  //       ]}
  //       getOptionLabel={(data) => data.label}
  //        {...rest}
  //     />
  //   );
  // }

  // if (formDataItem.type == "gender") {
  //   return (
  //     <DropDownComponent
  //       options={[
  //         { label: "Male", value: "male" },
  //         { label: "Female", value: "female" },
  //       ]}
  //       getOptionLabel={(data) => data.label}
  //        {...rest}
  //     />
  //   );
  // }

  // if (formDataItem.type == "testType") {
  //   return (
  //     <DropDownComponent
  //       options={[
  //         { label: "SSC", value: "SSC" },
  //         { label: "HSC", value: "HSC" },
  //         { label: "BE", value: "BE" },
  //         { label: "B-Tech", value: "B-Tech" },
  //         { label: "M-Tech", value: "M-Tech" },
  //         { label: "GRE", value: "GRE" },
  //         { label: "GMAT", value: "GMAT" },
  //         { label: "Other", value: "Other" },
  //       ]}
  //       getOptionLabel={(data) => data.label}
  //        {...rest}
  //     />
  //   );
  // }

  // if (formDataItem.type == "admitStatusType") {
  //   return (
  //     <DropDownComponent
  //       options={[
  //         { label: "Yet To Apply", value: "Yet To Apply" },
  //         { label: "Awaiting Decision", value: "Awaiting Decision" },
  //         { label: "Received  Admit", value: "Received  Admit" },
  //         { label: "Already On Campus", value: "Already On Campus" },
  //       ]}
  //       getOptionLabel={(data) => data.label}
  //       {...rest}
  //     />
  //   );
  // }

  return <TextFieldComponent mask={mask} type={formDataItem.type} error={error} helperText={helperText} {...rest} />;
}

export default Input;
