import logo from "./logo.svg";
import "./App.css";
import DrawerAppBar from "./dashboard/DrawerApp";
import { useEffect, useState } from "react";
import API from "./services/axios";

function App({ config }) {
  const [isError, setError] = useState(false);

  useEffect(() => {
    localStorage.setItem("apiHost", config?.apiHost);
    API({
      method: "post",
      url: `${config?.apiHost}/login`,
      data: {
        username: "system@finezza.in",
        password: "password",
        productValue: 16,
      },
    })
      .then((response) => {
        if (response?.data?.token)
          localStorage.setItem("accessToken", response?.data?.token);
      });
  }, []);

  if (!localStorage.getItem("accessToken")) {
    return null;
  }
  return (
    <>
      <DrawerAppBar
        isError={isError}
      />
    </>
  );
}

export default App;
