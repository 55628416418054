import React from "react";
import { Box, Button, Typography } from "@mui/material";

function ErrorUI(props) {
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    setFlag(!flag);
  }, [props]);

  return (
    <Box
      title="500: Something went wrong"
       align="center"
       mt={20}
      style={{ width: "100vw", align: "center" }}
    >
      <Typography
        align="center"
        variant={"h4"}
        color="textPrimary"
        style={{ marginBottom: 10 }}
      >
        Something went wrong !
      </Typography>
      <Box mt={6} w={"100%"} display="flex" justifyContent="center">
        <img
          alt="Under development"
          src="/undraw_server_down_s4lk.svg"
          style={{ width: 500, height: 500 }}
        />
      </Box>
    </Box>
  );
}

export default ErrorUI;
