import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import API from "../services/axios";
import { useRef } from "react";

// const options = ['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry', 'Fig', 'Grape'];

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const displayLimit = 50; // Change this value to your desired limit

function AutocompleteWithInputAdornment({ value, name, label, maxLength, display,onChange, ...rest }) {
  const timeoutRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleInputChange = (event, newValue) => {
    onChange('city', newValue)
    onChange('state', newValue)
    onChange(name, newValue)
  };

  const handleScroll = (event) => {
  };

  const onChangeHandler = (e) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      API({
        method: "get",
        url: `api/constants/pincode/inputType?fieldName=pincode&pincode=${e.target.value}`,
      })
        .then((response) => {
          setOptions(response.data.pincode);
        })
        .catch((error) => {});
    }, 1000);
  };

  return (
    <Autocomplete
      name={name}
      value={value?.pincode ? value : { pincode: "" }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={display}
      loading={options == null}
      onChange={handleInputChange}
      options={options} // Limit the options using slice
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          onChange={onChangeHandler}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            maxLength: 6, // Set the maximum length for the input
          }}
        />
      )}
    />
  );
}

export default AutocompleteWithInputAdornment;
