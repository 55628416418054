import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Box,
  Grid,
  Button,
} from "@mui/material";

import Input from "../components/Input";
import { applcationFormList } from "../helper/formmaker";
import CustomizedSnackbars from "../components/SnackbarWithStatus";
import { useState } from "react";
import OTP_PAGE from "../dashboard/OTPDialog";
import API  from '../services/axios';



const initialState = {
  courseAdmitStatus: {},
  startMonth: {},
  testTaken: "",
  program: "",
  courseStartYear: {},
  nameOfTheInstitution: "",
  durationOfTheCourse: "",
  firstName: "",
  lastName: "",
  dob: null,
  gender: {},
  email: "",
  mobileNumber: "",
  pan_no: "",
  line1: "",
  pincode: {},
  city: {},
  state: {},
  isOtpVerified: false,
  requestedAmount: "",
  region: {
    _id: 3,
  },
  tenure: {
    _id: 24,
  },
  enquiryStage: {
    _id: 5,
  },
  loanProduct: {
    _id: 12,
  },
}
export default function ApplicationForm({ ...rest }) {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState("");
  const [openOtpDialog, setOpenOtpDialog] = React.useState(false);

  const [state, setState] = useState(initialState);

  const [error, setError] = useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleFinish = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validEmail = regex.test(state.email);
    if (!validEmail)
      setError({
        email: "Invalid Email!",
      });
    else {
      setError({});

      const request = structuredClone(state);

      request.gender = state.gender.name;
      request.city = state.pincode.city;
      request.state = state.pincode.state;
      request.pincode = state.pincode.pincode;

      API({
        method: "put",
        url: "/api/enquiry",
        data:request
      })
        .then((response) => {      
          setActiveStep(0)
          setState(initialState)
          setOpen("Details Successfully Submitted!");
        })
        .catch((error) => {
        });

      
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (name, value) => {
    setState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const sendOTP = () => {
    setOpenOtpDialog(true);
  };
  const checkStep = (step) => {
    const step1Val =
      Object.keys(state.startMonth).length !== 0 &&
      Object.keys(state.courseAdmitStatus).length !== 0 &&
      Object.keys(state.courseStartYear).length !== 0;

    if (step == 1) {
      return !step1Val;
    }
    const step2Val =
      state.testTaken &&
      state.nameOfTheInstitution &&
      state.program &&
      state.durationOfTheCourse;

    if (step == 2) {
      return !(step1Val && step2Val);
    }

    const step3Val =
      state.requestedAmount &&
      state.mobileNumber &&
      state.mobileNumber.length == 10;
    if (step == 3) {
      return !(step1Val && step2Val && step3Val);
    }

    const step4Val =
      Object.keys(state.gender).length !== 0 &&
      Object.keys(state.pincode).length !== 0 &&
      state.firstName &&
      state.lastName &&
      state.dob &&
      state.email &&
      state.pan_no &&
      state.line1;

    if (step == 4) {
      return !(step1Val && step2Val && step3Val && step4Val);
    }

    return false;
  };
  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          maxHeight: {
            xs: "100%",
            sm: "100%",
          },
          background: "#fff",
          p: 5,
          pt: 10,
        }}
      >
        {open && (
          <CustomizedSnackbars open={open} setOpen={setOpen} msg={open} />
        )}
        {openOtpDialog && (
          <OTP_PAGE
            snackOpen={open}
            setSnackOpen={setOpen}
            open={openOtpDialog}
            setOpen={setOpenOtpDialog}
            name={"isOtpVerified"}
            onChange={handleChange}
          />
        )}
        <Stepper activeStep={activeStep} orientation="horizontal">
          {applcationFormList.map((step, index) => (
            <Step key={step.formName}>
              <StepLabel
              >
                <Box
                  sx={{
                    display: {
                      lg: "block",
                      md: "block",
                      sm: "block",
                      xs: "none",
                    },
                  }}
                >
                  {step.formName}
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* {activeStep === applcationFormList.length ? (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <pre>{JSON.stringify(state, null, 4)}</pre>

            <Button
              variant="contained"
              onClick={handleFinish}
              sx={{ mt: 1, mr: 1 }}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
            >
              Back
            </Button>
          </Paper>
        ) : ( */}
        <>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ flexGrow: 1, width: "100%" }}>
              <Grid container spacing={3}>
                {applcationFormList[activeStep].list.map((field) => (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Input
                      formDataItem={field}
                      label={field.label}
                      name={field.name}
                      value={state[field.name]}
                      onChange={handleChange}
                      display={field.display}
                      disabled={
                        field.disabled ||
                        (field.name == "mobileNumber" && state.isOtpVerified)
                      }
                      mask={field.mask}
                      // onBlur={handleBlur}
                      error={error}
                      helperText={error[field.name]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <div>
              {activeStep === applcationFormList.length - 1 ? (
                <Button
                  variant="contained"
                  onClick={handleFinish}
                  disabled={checkStep(activeStep + 1)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Submit
                </Button>
              ) : !state.isOtpVerified && activeStep == 2 ? (
                <Button
                  variant="contained"
                  onClick={sendOTP}
                  sx={{ mt: 1, mr: 1 }}
                  disabled={checkStep(activeStep + 1)}
                >
                  Send OTP
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                  disabled={checkStep(activeStep + 1)}
                >
                  Next
                </Button>
              )}

              <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Back
              </Button>
            </div>
          </Box>
        </>
      </Box>
    </Box>
  );
}
