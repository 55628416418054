import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"; // Import the plugin
import { Stack, TextField } from "@mui/material";
dayjs.extend(customParseFormat);

export default function DatePickerComponent({
  format = "DD-MM-YYYY",
  views,
  value,
  name,
  onChange,
  ...rest
}) {
  return (
    <Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={views || ["year", "month", "day"]}
          format={format}
          value={value != null ? dayjs(value || dayjs().format(format), format): null}
          onChange={(newDate) => onChange(name,dayjs(newDate).format(format))}
          name={name}
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: format,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}
