export const courseData = [
  {
    type: "dropDown",
    name: "courseAdmitStatus",
    label: "What is your admit Status?",
    api: "api/constants/admitStatus/inputType?fieldName=courseAdmitStatus",
    display: (data) => {
      return data.name;
    },
  },
  {
    type: "dropDown",
    name: "startMonth",
    label: "Start Month",
    api: "api/constants/month/inputType?fieldName=startMonth",
    display: (data) => {
      return data.name;
    },
  },
  {
    type: "dropDown",
    name: "courseStartYear",
    label: "Course Start Year",
    api: "api/constants/year/inputType?fieldName=courseStartYear",
    display: (data) => {
      return data.name;
    },
  },
];

const universityData = [
  {
    type: "text",
    name: "testTaken",
    label: "Test Taken",
  },
  {
    type: "text",
    name: "nameOfTheInstitution",
    label: "Institution Name",
  },
  {
    type: "text",
    name: "program",
    label: "Program",
  },
  {
    type: "number",
    name: "durationOfTheCourse",
    label: "Course Duration",
    symbol: "months",
    symbolAign: "end",
    maxLength: 2,
    align: "right",
  },
];

export const verification = [
  {
    symbol: "rupees",
    symbolAign: "start",
    type: "number",
    name: "requestedAmount",
    label: "Request Amount",
    maxLength: 12,
  },
  {
    symbol: "mobile",
    symbolAign: "start",
    type: "number",
    name: "mobileNumber",
    label: "Mobile Number",
    maxLength: 10,
  },
];
export const applicantData = [
  {
    type: "text",
    name: "firstName",
    label: "First Name",
  },

  {
    type: "text",
    name: "lastName",
    label: "Last Name",
  },
  {
    type: "date",
    format: "DD/MM/YYYY",
    name: "dob",
    label: "Date of Birth",
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
  {
    type: "dropDown",
    name: "gender",
    label: "Gender",
    api: "api/constants/gender/inputType?fieldName=gender",
    display: (data) => {
      return data.name;
    },
  },
  {
    type: "text",
    name: "pan_no",
    label: "PAN Number",
    mask: "aaaaa9999a",
    regex:""
  },
  {
    type: "text",
    name: "line1",
    label: "Address Line 1",
  },
  {
    type: "pincode",
    name: "pincode",
    label: "Pincode",
    display: (data) => {
      return data.pincode;
    },
    maxLength: 6,
  },
  {
    type: "dropDown",
    name: "city",
    label: "City",
    disabled: true,
    display: (data) => {
      return data.city;
    },
  },
  {
    type: "dropDown",
    name: "state",
    label: "State",
    disabled: true,
    display: (data) => {
      return data.state;
    },
  },
];

export const applcationFormList = [
  {
    formName: "Step 1",
    list: courseData,
  },
  {
    formName: "Step 2",
    list: universityData,
  },
  {
    formName: "Step 3",
    list: verification,
  },
  {
    formName: "Step 4",
    list: applicantData,
  },
];
