import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export default function NumberTextFieldComponent({
  symbolAign,
  symbol,
  maxLength,
  name,
  onChange,
  value,
  ...rest
}) {
  const showValue = (value) => {
    if (symbol == "rupees") {
      return new Intl.NumberFormat("en-IN", {
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value ? parseInt(value) : 0);
    }
    return value;
  };
  const handleChange = (event) => {
    let newValue = event.target.value;
    if (symbol == "rupees") {
      newValue = newValue.replace(/,/g, "");
    }
    const reg = /^\d*$/;
    if (reg.test(newValue)) onChange(name, newValue);
  };
  const getAlign = (symbol) => {
    if (symbol == "percent") {
      return "right";
    }

    if (symbol == "months") {
      return "right";
    }
    return "left";
  };

  const getSymbol = (symbol) => {
    if (symbol == "percent") {
      return "%";
    }
    if (symbol == "mobile") {
      return "+91 ";
    }

    if (symbol == "rupees") {
      return <CurrencyRupeeIcon fontSize="small" />;
    }
    return symbol;
  };

  const adornment = {
    [symbolAign == "start" ? "startAdornment" : "endAdornment"]: (
      <InputAdornment
        position={symbolAign == "start" ? "start" : "end"}
        sx={{ marginRight: 0 }}
      >
        {getSymbol(symbol)}
      </InputAdornment>
    ),
    inputProps: {
      style: { textAlign: getAlign(symbol) },
      maxLength: maxLength || 50,
    },
  };

  return (
    <Stack>
      <TextField
        type="text"
        name={name}
        value={showValue(value)}
        onChange={handleChange}
        InputProps={adornment}
        {...rest}
      />
    </Stack>
  );
}
