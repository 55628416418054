import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// if (process.env.REACT_APP_ISDEV === "false") {
//   import("react-error-overlay").then((m) => {
//     m.stopReportingRuntimeErrors();
//   });
// }
fetch("/config.json")
  .then((r) => r.json())
  .then((config) => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <App config={config}/>
      </React.StrictMode>
    );
   
  });


reportWebVitals();
