import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ApplicationForm from "./ApplicationForm";
import { Grid } from "@mui/material";
import Error404View from "./ErrorPage";

const drawerWidth = 240;
const navItems = [
  { label: "Home", url: "#" },
  { label: "About", url: "https://finezza.in/#about" },
  { label: "Contact", url: "https://finezza.in/contact/" },
];

function DrawerAppBar({
  isError,
  ...rest
}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavClick = (event, url) => {
    event.preventDefault(); // Prevent default link behavior
    window.open(url, "_blank"); // Open in new tab
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Box sx={{ p: 1 }}>
          <img
            width="210px"
            height="55px"
            src="https://finezza.in/wp-content/uploads/2019/04/finezza-logo-1.png"
          />
        </Box>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText
                primary={item.label}
                onClick={(event) => handleNavClick(event, item.url)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <Box sx={{ p: 1 }}>
              <img
                width="210px"
                height="55px"
                src="https://finezza.in/blog/wp-content/uploads/2019/08/cropped-finezza-logo-256-white.png"
              />
            </Box>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item}
                sx={{ color: "#fff" }}
                onClick={(event) => handleNavClick(event, item.url)}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{
          width: "100%",
          height: "100vh",
          // background: "#f1f1f1",
        }}
      >
        <Grid container>
          {isError ? (
            <Grid item p={0}>
              <Error404View />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={7} lg={7} sx={{ mt: 5 }}>
                <ApplicationForm
                 
                />
                {/* <Table /> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                sx={{
                  // position: "fixed",
                  // right: "1rem",
                  // top: "8rem",
                  display: {
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                <Box className={"helperImage"} sx={{ mt: 20 }}>
                  <img
                    style={{ maxWidth: "100%", height: "auto" }}
                    src="https://i0.wp.com/finezza.in/wp-content/uploads/2020/09/what-is-finezza_.png?w=954&ssl=1"
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
