import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

let otps = ["", "", "", ""];

export default function OTPDialog({ open, setOpen,setSnackOpen, onChange, name }) {
  const [error, setError] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    otps = ["", "", "", ""];
    setError();
  };

  const textFieldRefs = React.useRef([]);

  const handleChange = (event, index) => {
    const { value } = event.target;
    otps[index] = value;
    if (value.length === 1 && index < textFieldRefs.current.length - 1) {
      textFieldRefs.current[index + 1].focus(); 
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      textFieldRefs.current[index - 1].focus(); 
    }
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if (otps.join("") == "1111") {
      otps = ["", "", "", ""];
      setError();
      handleClose();
      onChange(name, true);
      setSnackOpen("OTP Verified Successfully!");      

    } else {
      setError("Wrong OTP Entered!");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          style: {
            width: "400px", 
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Verify OTP
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <Typography sx={{ textAlign: "center", color: "red" }}>
            {error}
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {Array.from({ length: 4 }, (_, index) => (
              <Grid item key={index} style={{ textAlign: "center" }}>
                <TextField
                  autoFocus={index === 0} 
                  required
                  margin="dense"
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "center" },
                    maxLength: 1,
                  }}
                  onChange={(event) => handleChange(event, index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  inputRef={(el) => (textFieldRefs.current[index] = el)}
                  style={{ width: "60px", textAlign: "center" }} 
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ width: "100%", textAlign: "center" }}>
            <Button variant="contained" onClick={handleVerify}>
              Verify
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
