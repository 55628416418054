import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack } from "@mui/material";
import API from "../services/axios";

export default function DropDownComponent({
  display,
  name,
  onChange,
  disabled,
  api,
  ...rest
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    API({
      method: "get",
      url: api,
    })
      .then((response) => {
        setOptions(response.data[name]);
      })
      .catch((error) => {});
  }, []);

  if (!options){
    return null;
  }
  return (
    <Stack>
      <FormControl>
        <InputLabel>{rest.label}</InputLabel>
        <Select
          renderValue={display}
          name={name}
          onChange={(event) => {
            onChange(name, event.target.value);
          }}
          {...rest}
          disabled={disabled}
        >
          {options?.map((data, index) => (
            <MenuItem key={index} value={data}>
              {display(data)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
